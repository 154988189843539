import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { has, includes } from 'lodash-es';

import { IOfferBase, IPrice, TOfferPrice } from '@ess/types';

import { Styled } from '@tourop/components/OfferList/Offer/Offer.styles';

import { FIRST_PERSON_PRICE, TOTAL_PRICE } from '@ess/constants/search';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import getTotalPrice from '@tourop/components/MultiRoom/utils/getTotalPrice';

import Price from '@ess/ui/Price';
import Tooltip from '@ess/ui/Tooltip';
import Text from '@ess/ui/Text';

import PriceDetails from '@tourop/components/OfferElements/PriceDetails';
import PriceListContent from '@tourop/components/OfferElements/NewPriceList/PriceListConent';

type PriceElementProps = {
  item: any
  isMultiRoomMode: boolean
  commonProps: any
  onlineOffer?: any
  rooms?: any
  status?: any
  bookingDetails?: { id: string, status: string }
  disableBreakpoints?: boolean
}

const PriceElement = ({
  rooms = undefined,
  item,
  disableBreakpoints = false,
  status = undefined,
  isMultiRoomMode,
  onlineOffer = undefined,
  commonProps,
  bookingDetails = undefined,
}: PriceElementProps) => {
  const { t } = useTranslation();
  const { Base } = onlineOffer || item;
  const { Price: PriceData, Operator, OriginalPrice } = Base as IOfferBase;
  const { state: SFContext } = useContext(AppConfigContext);
  const { configOper } = SFContext;
  const operatorConfig = has(configOper, Operator as string) ? configOper[Operator as string] : configOper?.default ?? {};
  // if online price is 0.00 then show the original price
  const priceData = PriceData?.Total?.amount === '0.00' ? OriginalPrice : PriceData;

  return (
    <>
      {(bookingDetails?.status === 'XX') ? (
        <Styled.Offer__Price__Wrapper style={{ color: '#990000', fontWeight: 600 }}>
          {t('lbl_cancelled')}
        </Styled.Offer__Price__Wrapper>
      ) : (
        <Tooltip
          disabled={isMultiRoomMode || !onlineOffer}
          theme="bubble-wide"
          contentPadding={0}
          content={onlineOffer
            ? <PriceListContent minWidth={350} isLoading={false} offerData={onlineOffer} status={status}/> : <></>}
        >
          <Styled.Offer__Price__Wrapper>
            {!includes(disableBreakpoints ? [] : ['xxs', 'xs'], commonProps?.breakpoint) && has(priceData, FIRST_PERSON_PRICE) && (
              <Styled.Offer__Price highlight={commonProps?.priceType === FIRST_PERSON_PRICE}>
                <Price price={(priceData as TOfferPrice)[FIRST_PERSON_PRICE] as IPrice} color="personPrice"/>
              </Styled.Offer__Price>
            )}
            <Styled.Offer__Price highlight={commonProps?.priceType === TOTAL_PRICE}>
              {!isMultiRoomMode ? (
                <>
                  {!operatorConfig?.isFullPriceDisabled && has(priceData, TOTAL_PRICE)
                    ? <Price price={(priceData as TOfferPrice)[TOTAL_PRICE] as IPrice} color="totalPrice" showArrow/>
                    : null}
                </>
              ) : rooms && rooms.length > 0 ? (
                <Price price={getTotalPrice(rooms)} color="totalPrice" showArrow/>
              ) : (
                <Tooltip content={t('check_recommended_rooms')}>
                  <Text
                    fontSize={12}
                    fontWeight="bold"
                    lineHeight="14px"
                  >
                    {true ? 'MRF' : t('multiroom').toLowerCase()}
                  </Text>
                </Tooltip>
              )}
            </Styled.Offer__Price>
          </Styled.Offer__Price__Wrapper>
        </Tooltip>
      )}
    </>
  );
};

export default PriceElement;
