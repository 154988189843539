import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { has } from 'lodash-es';

import Tooltip from '@ess/ui/Tooltip';

type FormatWithOldProps = {
  obj: any;
  property: string;
  children?: (value: any) => React.ReactElement;
};

const OnlineWarnOld = ({
  children = undefined,
  property,
  obj,
}: FormatWithOldProps) => {
  const { t } = useTranslation();
  return (
    <>
      {has(obj, property) ? children?.(obj[property]) : ''}
      {has(obj, `${property}_old`) ? (
        <Tooltip
          content={(
            <>
              {t('value_before_online_check')}
              <br />
              {children?.(obj[`${property}_old`])}
            </>
          )}
        >
          <span>
            <FontAwesomeIcon color="orange" icon={faExclamationTriangle} />
          </span>
        </Tooltip>
      ) : (
        ''
      )}
    </>
  );
};

export default OnlineWarnOld;
