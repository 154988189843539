import React, { CSSProperties, forwardRef, memo } from 'react';
import {
  TypographyProps, ColorProps, SpaceProps, FontStyleProps, LayoutProps, TextStyleProps,
} from 'styled-system';

import { Styled } from './Text.styles';

type StyledSystemProps = ColorProps & SpaceProps & TypographyProps & LayoutProps & TextStyleProps;

type CustomTextStyles = {
  whiteSpace?: string;
  style?: StyledSystemProps;
}
type TextProps = {
  children?: React.ReactNode | React.ReactElement;
  style?: CSSProperties;
  id?: string;
  as?: any;
} & StyledSystemProps & FontStyleProps & CustomTextStyles;

const Text = forwardRef<HTMLDivElement, TextProps>(({
  as = 'div',
  children = null,
  id = undefined,
  fontSize = 'body',
  lineHeight = 1,
  whiteSpace = 'normal',
  style = {},
  ...props
}, ref) => (
  <Styled.Text
    ref={ref}
    as={as}
    fontSize={fontSize}
    lineHeight={lineHeight}
    whiteSpace={whiteSpace}
    style={style}
    {...{ ...id ? { id } : {} }}
    {...props}
  >
    {children}
  </Styled.Text>
));

export default memo(Text);
