import React, { CSSProperties, forwardRef } from 'react';
import { SpaceProps } from 'styled-system';

import { Styled } from './Chip.styles';

export type SizeEnum = 'small' | 'medium' | 'large';
export type VariantEnum = 'default' | 'primary' | 'secondary' | 'highlighted' | 'error' | 'border' | 'borderHighlighted';

type ChipGroupProps = {
  children: React.ReactNode,
};

type ChipProps = {
  label: string | React.ReactElement
  size?: SizeEnum
  variant?: VariantEnum
  style?: CSSProperties
  as?: any
} & SpaceProps;

const ChipGroup = forwardRef<HTMLDivElement, ChipGroupProps>(({
  children,
}, ref) => (
  <Styled.ChipGroup ref={ref}>
    {children}
  </Styled.ChipGroup>
));

const Chip = forwardRef<HTMLDivElement, ChipProps>(({
  label,
  as = 'div',
  size = 'small' as SizeEnum,
  variant = 'primary' as VariantEnum,
  style = {},
  ...props
}, ref) => (
  <Styled.Chip
    as={as}
    ref={ref}
    size={size}
    variant={variant}
    style={style}
    {...props}
  >
    {label}
  </Styled.Chip>
));

export default Chip;
export {
  ChipGroup,
};
