import React from 'react';

import Radio from '@ess/ui/Form/Radio';

import { Styled } from '../ModernSelect.styles';

type OptionsProps = {
  data: any
  isSelected: boolean
  onClick: (data: any) => void
  renderOption?: (data: any) => React.ReactElement
}

const Option = ({
  data,
  isSelected,
  onClick,
  renderOption = undefined,
}: OptionsProps) => {
  const onClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    onClick(data);
  };

  return (
    <Styled.ModernSelect__Item
      isSelected={isSelected}
      onClick={onClickHandler}
    >
      <Styled.ModernSelect__Item__Radio>
        <Radio
          name="option"
          checked={isSelected}
          value={data.value}
        />
      </Styled.ModernSelect__Item__Radio>
      <Styled.ModernSelect__Item__Value>
        {renderOption ? renderOption(data) : data.label}
      </Styled.ModernSelect__Item__Value>
    </Styled.ModernSelect__Item>
  );
};

export {
  Option,
};
