import React, {
  createElement, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  groupBy, has, isArray, isString, isUndefined, orderBy, uniq,
} from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faTimes, faUser,
} from '@fortawesome/pro-solid-svg-icons';

import { CHECK_ONLINE_ENDPOINT, ONLINE_SERVICE_TIMEOUT, SEARCH_API } from '@ess/constants/api';

import { ISelectedRoom } from '@tourop/components/MultiRoom/types';

import { promiseRequest } from '@ess/v5-data-provider/request';

import Tooltip from '@ess/ui/Tooltip';
import FlexBox from '@ess/ui/FlexBox';
import Text from '@ess/ui/Text';
import Loader from '@ess/ui/Loader';
import Alert from '@ess/ui/Alert';
import Box from '@ess/ui/Box';
import { COMBINED_OFFERS } from '@ess/constants/offer';
import RoomDetail from '@tourop/components/OfferList/Offer/MultiRoomPreview/RoomDetail';
import { STATUS_COLOR, STATUS_ICONS, STATUS_TYPES } from '@ess/constants/status';
import { useDebouncedCallback } from 'use-debounce';

type MultiRoomPreviewProps = {
  data: ISelectedRoom[];
  availableOffers?: any[]
  spaceSavingMode?: boolean
  updateUserRoomSelection?: (mainOfferId: string, legacyHash: string, groupName: string, newOffer: ISelectedRoom) => void
  item: any;
  isLoading: boolean;
  offerId: string;
  fetchRooms: any;
  updateMultiRoomData: any;
};

type RoomsPaxTooltipProps = {
  pax: any;
  parentOfferId: string
};

const RoomsPaxTooltip = ({ pax, parentOfferId }: RoomsPaxTooltipProps) => {
  const { t } = useTranslation();
  return (
    <FlexBox flexDirection="initial" alignItems="center" maxWidth={250}>
      <Text mr="tiny">{pax.Adult.value}</Text>
      <Text>
        {t('adult_interval', {
          postProcess: 'interval',
          count: pax.Adult.value,
        }).toLowerCase()}
      </Text>
      {pax?.Child?.value > 0 && (
        <>
          <Text mx="tiny">•</Text>
          <Text mr="tiny">{pax.Child.value}</Text>
          <Text>
            {t('child_interval', {
              postProcess: 'interval',
              count: pax.Child.value,
            }).toLowerCase()}
          </Text>
        </>
      )}
    </FlexBox>
  );
};

const spaceSavingStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'initial',
  overflow: 'hidden',
  maxHeight: '20px',
  whiteSpace: 'nowrap',
  flexWrap: 'nowrap',
  maxWidth: '100%',
  marginTop: '2px',
};

const MultiRoomPreview = ({
  item,
  data,
  offerId,
  fetchRooms,
  isLoading,
  availableOffers = [],
  spaceSavingMode = true,
  updateUserRoomSelection = undefined,
  updateMultiRoomData,
}: MultiRoomPreviewProps) => {
  const { t } = useTranslation();
  const [offersToCheck, setOffersToCheck] = useState<string[]>([]);
  const detailsRef = useRef<any>(null);
  const [detailsWidth, setDetailsWidth] = useState(0);
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [details, setDetails] = useState<any[]>([]);

  const separatorWidth = 13.625;
  const userCounterWith = 47;
  const roomCounter = 25;
  const isCombinedOffer = COMBINED_OFFERS.includes(
    item?.offer?.Accommodation?.Type?.Id as string,
  );

  /**
   * Check room status handler.
   * @param offerHash
   */

  const checkAvailRequest = async (offerHash: string) => {
    const requestData = {
      actions: ['checkstatus'],
      offerIds: [offerHash],
      includeTFG: false,
    };

    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve, reject) => promiseRequest(
      `${SEARCH_API}${CHECK_ONLINE_ENDPOINT}`,
      requestData,
      3,
      undefined,
      ONLINE_SERVICE_TIMEOUT,
    )
      .then((response) => {
        resolve(response?.results);
      })
      .catch((error) => {
        reject(error);
      }),
    );
  };

  const checkAllAvailRequest = async (offerHashList: string[]) => {
    setLoadingIds(offerHashList);
    Promise.all(uniq(offerHashList).map((item) => checkAvailRequest(item))).then(
      (results: any) => {
        const onlineOffers: any = {};
        results.map((item: any) => {
          onlineOffers[item[0].OfferId] = item[0];
        });
        const selectedRooms = data?.map((selectedRoom: any, index) => {
          const checkItem = onlineOffers[selectedRoom?.Offer?.Base?.OfferId];
          if (checkItem !== undefined) {
            const item = checkItem.offer;
            return {
              ...selectedRoom,
              Group: {
                ...selectedRoom.Group,
                description: `${t('room')[0].toUpperCase()}.${index + 1}`,
              },
              Offer: {
                ...selectedRoom.Offer,
                Base: {
                  ...selectedRoom.Offer.Base,
                  Availability: {
                    ...selectedRoom.Offer.Base.Availability,
                    ...item.Base.Availability,
                  },
                  Price: {
                    ...selectedRoom.Offer.Base.Price,
                    ...(item?.Base?.Price
                      ? {
                        FirstPerson: {
                          ...selectedRoom.Offer.Base.Price.FirstPerson,
                          amount: item.Base.Price.FirstPerson.amount,
                        },
                        Total: {
                          ...selectedRoom.Offer.Base.Price.Total,
                          amount: item.Base.Price.Total.amount,
                        },
                      }
                      : {}),
                  },
                },
              },
            };
          }
          return selectedRoom;
        });
        setLoadingIds([]);
        updateMultiRoomData(offerId, selectedRooms);
      },
    );
  };

  const fetchRoomsHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!isCombinedOffer) {
      fetchRooms(offerId);
    }
  };

  const onChange = (legacyHash: string, groupName: string, newOffer: ISelectedRoom) => {
    if (updateUserRoomSelection) {
      updateUserRoomSelection(offerId, legacyHash, groupName, newOffer);
    }
  };

  const onDebouncedResize = useDebouncedCallback(() => {
    setDetailsWidth(detailsRef.current.clientWidth - (separatorWidth * 2) - userCounterWith - roomCounter);
  }, 200);

  const onResize = () => {
    const calculatedWidth = detailsRef.current.clientWidth - (separatorWidth * 2) - userCounterWith - roomCounter;
    const difference = Math.abs(detailsWidth - calculatedWidth) > 10;

    if (difference) {
      setDetailsWidth(calculatedWidth);
    }

    onDebouncedResize();
  };

  useEffect(() => {
    const multiRoomDetailsNew = data?.map((item: any, index) => {
      const totalPax = (item?.Group?.pax?.Adult?.value ?? 0) + (item?.Group?.pax?.Child?.value ?? 0);

      return {
        amount: 1,
        participants: {
          pax: item.Group.pax,
          totalPax,
        },
        offerIdForComparing: item.offerIdForComparing,
        group: item.Group,
        name: item.Offer.Accommodation?.Room?.Name,
        service: item.Offer.Accommodation?.Service?.Name,
        availabilityStatus: item.Offer.Base?.Availability?.base,
        availabilityMessage: item.Offer.Base?.Availability?.message,
        offerId: item.Offer?.Base?.OfferId,
        offer: item.Offer,
        key: `${index}_${item.Offer.Base?.OfferId}`,
      };
    }) ?? [];

    setDetails(multiRoomDetailsNew);
  }, [data]);

  useEffect(() => {
    if (offersToCheck.length) {
      checkAllAvailRequest(offersToCheck);
    }
  }, [offersToCheck]);

  useEffect(() => {
    const offersToCheck = data
      ? data.filter(
        (item: ISelectedRoom) => item.Offer.Base?.Availability === undefined,
      )
      : [];
    setOffersToCheck(
      offersToCheck.map((item) => item.Offer.Base?.OfferId as string),
    );
  }, [data]);

  useEffect(() => {
    if (detailsRef?.current !== undefined && detailsRef.current !== null && spaceSavingMode) {
      if (detailsWidth === 0) {
        setDetailsWidth(detailsRef.current.clientWidth - (separatorWidth * 2) - userCounterWith - roomCounter);
      }
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
    return () => {

    };
  }, [detailsRef, spaceSavingMode, details]);

  if (isCombinedOffer) {
    return (
      <FlexBox mt="tiny">
        <Alert size="small" showBorder={false} severity="error">
          {t('lbl_multi_room_unavailable_for_offers_with_extra_hotel')}
        </Alert>
      </FlexBox>
    );
  }

  if (isLoading) {
    return (
      <FlexBox alignItems="center" height={30} width="100%">
        <Loader type="dots" size="20px"/>
        <Text fontSize="12px" ml="small">
          {t('checking_recommended_rooms')}
        </Text>
      </FlexBox>
    );
  }

  const content = (
    <>
      {details.map((item: any) => (
        <FlexBox key={item.key} flexDirection="column" mt="tiny" maxWidth="100%">
          <FlexBox alignItems="flex-start">
            <FlexBox
              mr="tiny"
              alignItems="center"
              flexShrink={0}
              justifyContent="center"
              style={{
                width: 20,
                height: 20,
                backgroundColor: '#ddd',
                borderRadius: 4,
              }}
            >
              <Text fontSize={11} fontWeight="bold" color="#333333">
                {item.group.description.trim().replace(' ', '')}
              </Text>
            </FlexBox>
            <Box
              style={spaceSavingMode ? spaceSavingStyles : { marginTop: '2px' }}
            >
              <RoomDetail
                isLoading={loadingIds.includes(item.offerId)}
                detailsWidth={detailsWidth}
                spaceSavingMode={spaceSavingMode}
                onChange={onChange}
                item={item}
                roomName={item.name}
                serviceName={item.service}
                name={item.name}
                availableOffers={availableOffers}
                sectionName="roomName"
              />
              <Text as="span" mx="tiny">
                /
              </Text>
              <RoomDetail
                isLoading={loadingIds.includes(item.offerId)}
                detailsWidth={detailsWidth}
                spaceSavingMode={spaceSavingMode}
                onChange={onChange}
                item={item}
                roomName={item.name}
                serviceName={item.service}
                name={item.service}
                availableOffers={availableOffers}
                sectionName="roomService"
              />
              <Text as="span" mx="tiny">
                /
              </Text>
              <FlexBox display="inline-flex" flexShrink={0} flexWrap="nowrap">
                <FlexBox alignItems="center" display="inline-flex" as="span">
                  <Tooltip
                    content={
                      <RoomsPaxTooltip parentOfferId={offerId} pax={item.participants.pax}/>
                    }
                  >
                    <Box as="span">
                      <Text fontSize={11} as="span" mr="tiny">
                        <FontAwesomeIcon icon={faUser}/>
                      </Text>
                      <FlexBox
                        alignItems="center"
                        display="inline-flex"
                        as="span"
                      >
                        <Text as="span">
                          {item.participants.pax.Adult.value}
                        </Text>
                        <Text as="span">
                          :
                          {item.participants.pax.Child.value}
                        </Text>
                      </FlexBox>
                    </Box>
                  </Tooltip>
                </FlexBox>
                <FlexBox>
                  {loadingIds.includes(item.offerId) ? (
                    <Tooltip
                      content="please wait loading status"
                    >
                      <Box
                        as="span"
                        style={{
                          marginLeft: '4px',
                          overflow: 'hidden',
                          maxWidth: '10px',
                          width: '12px',
                          height: '12px',
                          minWidth: '12px',
                          position: 'relative',
                        }}

                      >
                        <Box
                          style={{
                            position: 'absolute',
                            top: '2px',
                            bottom: '0px',
                            left: '0px',
                            right: '0px',
                          }}
                        >
                          <Loader
                            size="12px"
                          />
                        </Box>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      content={item.availabilityMessage}
                    >
                      <FlexBox
                        as="span"
                        marginTop="2px"
                        marginLeft="2px"
                      >
                        {!isUndefined(STATUS_ICONS[STATUS_TYPES[item.availabilityStatus]]) && React.createElement(
                          STATUS_ICONS[STATUS_TYPES[item.availabilityStatus]] as any,
                          {
                            color: STATUS_COLOR[STATUS_TYPES[item?.availabilityStatus as string]],
                          },
                        )}
                      </FlexBox>
                    </Tooltip>
                  )}
                </FlexBox>
              </FlexBox>

            </Box>
          </FlexBox>
        </FlexBox>
      ))}
    </>
  );

  return details.length > 0 ? (
    <FlexBox
      flexDirection="column"
      position="relative"
      height={spaceSavingMode ? `${details.length * 25}px` : 'unset'}
      ref={detailsRef}
    >
      {spaceSavingMode ? (
        <FlexBox
          flexDirection="column"
          position="absolute"
          maxWidth="100%"
          style={{
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
          }}
        >
          {content}
        </FlexBox>
      ) : content}
    </FlexBox>
  ) : !isLoading && isArray(data) && !data.length ? (
    <FlexBox mt="tiny">
      <Alert size="small" showBorder={false} severity="error">
        {t('not_all_groups_found')}
      </Alert>
    </FlexBox>
  ) : (
    <FlexBox mt="tiny" onClick={fetchRoomsHandler}>
      <Alert size="small" showBorder={false} severity="info">
        {t('show_recommended_rooms')}
      </Alert>
    </FlexBox>
  );
};

export default MultiRoomPreview;
