import { Workbox } from 'workbox-window';
import { createRoot } from 'react-dom/client';

import FlexBox from '@ess/ui/FlexBox';

import { Styled } from '@ess/ui/Modal/ModalsTypes/Modal.styles';

export default function registerServiceWorker() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if ('serviceWorker' in navigator) {
    const wb = new Workbox('sw.js');

    wb.addEventListener('installed', (event) => {
      console.log('debugServiceWorker', JSON.stringify(event));
      /**
       * We have the condition - event.isUpdate because we don't want to show
       * this message on the very first service worker installation,
       * only on the updated
       */
      if (event.isUpdate) {
        const isTransAvailable = typeof window.i18n === 'function';
        const root = createRoot(document.getElementById('pwa_root'));
        root.render(
          <>
            <Styled.Modal__Notification>
              <Styled.Modal__Notification_Header>
                {isTransAvailable
                  ? window.i18n('lbl_new_app')
                  : 'New Version Available'}
              </Styled.Modal__Notification_Header>
              <div>
                <FlexBox display="flex" padding="10px">
                  {isTransAvailable
                    ? window.i18n('lbl_refresh_message')
                    : 'New app update is available!. Click on Refresh button to refresh.'}
                </FlexBox>
              </div>
              <Styled.Modal__Notification_Controls>
                <button
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                  style={{
                    display: 'inline-flex',
                    backgroundColor: 'var(--button-background-color)',
                    color: 'var(--white-color)',
                    borderColor: 'var(--button-background-color)',
                    borderRadius: 'var(--button-border-radius)',
                  }}
                >
                  {isTransAvailable ? window.i18n('refresh') : 'Refresh'}
                </button>
              </Styled.Modal__Notification_Controls>
            </Styled.Modal__Notification>
            <Styled.ModalOverlay_Notification />
          </>,
        );
      }
    });
    wb.register();
  }
}
