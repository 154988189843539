import React, {
  forwardRef, MutableRefObject, ReactElement, Ref, useEffect, useRef, useState,
} from 'react';
import ReactSelect, {
  OptionTypeBase, ActionMeta, ValueType, MenuPlacement, createFilter,
} from 'react-select';
import StateManager from 'react-select';
import { useTranslation } from 'react-i18next';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { isArray, has, isUndefined } from 'lodash-es';

import { MULTIPLE_OPTION_VALUE } from '@ess/constants/search';

import { findSelectOption } from '@ess/utils';

import MenuList from './ReplacedComponents/MenuList';
import DropdownIndicator from './ReplacedComponents/DropdownIndicator';
import ClearIndicator from './ReplacedComponents/ClearIndicator';
import LoadingIndicator from './ReplacedComponents/LoadingIndicator';
import Control from './ReplacedComponents/Control';
import CustomOption from './ReplacedComponents/FastOption';

import SimpleSelectCustomStyles from './SimpleSelect.styles';

const getUngroupedOptions = (groupedOptions: OptionTypeBase[]) => {
  let ungroupedOptions: OptionTypeBase[] = [];

  groupedOptions.map(({ options }) => {
    ungroupedOptions = [...ungroupedOptions, ...options];
  });

  return ungroupedOptions;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const addMultipleOption = (options: OptionTypeBase[], t: Function): OptionTypeBase[] => {
  const newOptions = [...options];

  newOptions.unshift({
    value: MULTIPLE_OPTION_VALUE,
    label: t('multiple_option'),
    isMultiple: true,
  });

  return newOptions;
};

export type SelectProps = {
  options: OptionTypeBase[]
  name?: string
  value?: any
  placeholder?: string
  multiple?: boolean
  components?: any,
  minMenuWidth?: string
  maxMenuHeight?: number
  onChange?: (selected: OptionTypeBase, actionMeta?: ActionMeta<OptionTypeBase>) => void
  onMenuOpen?: () => void
  isSearchable?: boolean
  isClearable?: boolean
  menuPlacement?: MenuPlacement
  isLoading?: boolean
  isDisabled?: boolean
  height?: string
  openMenuOnFocus?: boolean
  menuShouldBlockScroll?: boolean
  menuShouldScrollIntoView?: boolean
  button?: boolean | ReactElement
  menuPortalTarget?: HTMLElement | null
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void
  onBlur?: (event: React.FocusEvent) => void
}

const SimpleSelect = forwardRef<HTMLElement, SelectProps>(({
  name = '',
  value = null,
  options,
  placeholder = '',
  onChange = undefined,
  onBlur = undefined,
  onFocus = undefined,
  onMenuOpen = undefined,
  isSearchable = undefined,
  height = undefined,
  isClearable = true,
  isLoading = false,
  isDisabled = false,
  menuPlacement = 'auto' as MenuPlacement,
  multiple = false,
  maxMenuHeight = undefined,
  menuShouldBlockScroll = false,
  menuShouldScrollIntoView = false,
  menuPortalTarget = undefined,
  components = undefined,
  ...props
}, ref) => {
  const cRef = useRef<any>(null);
  const { t } = useTranslation();
  const [isSearchEnabled, setIsSearchEnabled] = useState(isSearchable);
  const [isMobileSearchEnabled, setIsMobileSearchEnabled] = useState(false);
  const [selectedValue, setSelectedValue] = useState<ValueType<OptionTypeBase, boolean>>(null);
  const hasGroupedOptions = has(options[0], 'options');
  const ungroupedOptions = hasGroupedOptions ? addMultipleOption(getUngroupedOptions(options), t) : [];
  const selectOptions: OptionTypeBase[] = multiple && options.length ? addMultipleOption(options, t) : options;
  const optionsCount = hasGroupedOptions ? getUngroupedOptions(options).length : options.length;

  const onBlurHandler = (event: React.FocusEvent) => {
    if (isMobileOnly) {
      setIsSearchEnabled(false);
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  const onFocusHandler = (event: React.FocusEvent<HTMLElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const changeHandler = (selected: OptionTypeBase, actionMeta: ActionMeta<OptionTypeBase>) => {
    if (onChange) {
      onChange(selected, actionMeta);
    }
  };

  const onSearchIconClick = (event: React.MouseEvent) => {
    const currentRef = (ref ?? cRef) as MutableRefObject<any>;

    setIsSearchEnabled(true);

    if (currentRef?.current?.select) {
      setTimeout(() => {
        currentRef.current.select.focus();
      }, 100);
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const setFocusedOption = () => {
    const currentRef = (ref ?? cRef) as MutableRefObject<any>;
    if (currentRef?.current) {
      if (currentRef.current.select) {
        currentRef.current.select.scrollToFocusedOptionOnUpdate = true;
      }

      currentRef?.current?.select?.setState({
        focusedOption: options.filter((option: any) => option.value === value)[0],
      });
    }
  };

  const onMenuOpenHandler = () => {
    if (onMenuOpen) {
      onMenuOpen();
    }

    setFocusedOption();
  };

  useEffect(() => {
    const isMultipleValue = multiple && isArray(value);
    const multipleValue = value ? value.length > 1 ? MULTIPLE_OPTION_VALUE : value[0] : [];
    const parsedValue = value !== undefined ? isMultipleValue ? multipleValue : value : null;
    const selectedOption = findSelectOption(hasGroupedOptions ? ungroupedOptions : selectOptions, parsedValue);

    setSelectedValue(selectedOption || null);
  }, [value, selectOptions]);

  useEffect(() => {
    const isSearchEnabled = !isUndefined(isSearchable) ? isSearchable : optionsCount > 10;

    setIsSearchEnabled(!isMobileOnly && isSearchEnabled);
    setIsMobileSearchEnabled(isMobileOnly && isSearchEnabled);
  }, [optionsCount, isSearchable]);

  useEffect(() => {
    setFocusedOption();
  }, [options]);

  return (
    <ReactSelect
      classNamePrefix="mx-react-select"
      ref={(ref ?? cRef) as Ref<StateManager>}
      passRef={(ref ?? cRef) as Ref<StateManager>}
      name={name}
      styles={SimpleSelectCustomStyles}
      value={selectedValue}
      options={selectOptions}
      onChange={(selected, actionMeta) => changeHandler(selected, actionMeta)}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      onSearchIconClick={onSearchIconClick}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={isMobile ? false : isSearchEnabled}
      isMobileSearchEnabled={isMobileSearchEnabled}
      isLoading={isLoading}
      isDisabled={isDisabled}
      blurInputOnSelect
      height={height}
      isOptionDisabled={(option) => option.disabled}
      loadingMessage={() => t('loading_options')}
      noOptionsMessage={() => t('no_results')}
      filterOption={createFilter({ ignoreAccents: false })}
      onMenuOpen={onMenuOpenHandler}
      menuPlacement={menuPlacement}
      menuShouldBlockScroll={menuShouldBlockScroll}
      menuShouldScrollIntoView={menuShouldScrollIntoView}
      {...{ ...(menuPortalTarget !== null) ? { menuPortalTarget: menuPortalTarget || document.body } : {} }}
      {...{ ...(maxMenuHeight) ? { maxMenuHeight } : isMobileOnly ? { maxMenuHeight: 245 } : { maxMenuHeight: 345 } }}
      minMenuHeight={100}
      multiple={multiple}
      components={{
        Option: CustomOption,
        MenuList,
        Control,
        LoadingIndicator,
        ClearIndicator,
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ...components,
      }}
      {...props}
    />
  );
});

export default SimpleSelect;
export {
  LoadingIndicator,
  ClearIndicator,
  DropdownIndicator,
};
