import styled from 'styled-components';
import Text from '@ess/ui/Text';
import Box from '@ess/ui/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string } from 'yup';
import FlexBox from '@ess/ui/FlexBox';
import { isMobileOnly } from 'react-device-detect';

const Room__Detail__Text = styled(Text)`
  font-weight: bold;
  text-decoration: inherit;
  white-space: initial;
`;
const Room__Detail__Holder = styled(Box)`
  // display: inline-block;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;
const Space__Saving__Holder = styled(FlexBox)<{ dataMaxWidth: string }>`
  flex-wrap: nowrap;
  white-space: nowrap;
  flex-shrink: 1;
  max-width: ${({ dataMaxWidth }) => dataMaxWidth};
`;
const Space__Saving__Text = styled(Text)`
  overflow: hidden;
  flex-shrink: 1;
  font-weight: bold;
  white-space: ${isMobileOnly ? 'initial' : 'nowrap'};
  text-overflow: ellipsis;
  cursor: pointer;

`;

const Room__Detail__Chevron__Icon = styled(FontAwesomeIcon)`
  color: darkgreen;
  margin-left: 3px;
`;
const Room__Status__Icon = styled(FontAwesomeIcon)<{ color: string }>`
  margin-left: 3px;
  margin-top: 2px;
  color: ${({ color }) => color};
`;

const Room__Details__Icon = styled(FontAwesomeIcon)`
  margin: 0 3px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.navy};
`;

export const Styled = {
  Space__Saving__Text,
  Space__Saving__Holder,
  Room__Details__Icon,
  Room__Status__Icon,
  Room__Detail__Chevron__Icon,
  Room__Detail__Holder,
  Room__Detail__Text,
};
