import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 999;
  flex-shrink: 0;
  height: var(--header-height);
`;

const Header__Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  ${({ theme }) => `${theme.mediaQueries.md} {
     justify-content: space-between;
  }`}
`;

const Header__Start = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;

  ${({ theme }) => `${theme.mediaQueries.md} {
     flex-grow: 0;
  }`}
`;

const Header__End = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Header__User = styled.div`
  margin-left: ${({ theme }) => theme.space.medium};
`;

const Header__Top = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: var(--top-bar-height);
  background-color: ${({ theme }) => theme.colors.navy};
`;

const Header__Bottom = styled.div`
  height: 36px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Header__Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 180px;
    height: auto;
  }
`;

const Header__DesktopMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left:  ${({ theme }) => theme.space.medium};
`;

export const Styled = {
  Header,
  Header__Wrapper,
  Header__Top,
  Header__Bottom,
  Header__Start,
  Header__End,
  Header__Logo,
  Header__User,
  Header__DesktopMenu,
};
