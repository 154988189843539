import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { IDictionary } from '@ess/types';

import { IconButton } from '@ess/ui/Button';

export enum Direction {
  Left = 'Left',
  Right = 'Right',
}

type NavigationArrowProps = {
  direction: Direction
  onClick: (direction: Direction) => void
  onMouseUp?: (direction: Direction) => void
  onMouseDown?: (direction: Direction) => void
  style?: CSSProperties
}

const iconMap: IDictionary<any> = {
  Left: faChevronLeft,
  Right: faChevronRight,
};

const positionMap: IDictionary<any> = {
  Left: {
    top: '50%',
    left: '-12px',
    marginTop: '-22px',
  },
  Right: {
    top: '50%',
    right: '-12px',
    marginTop: '-22px',
  },
};

const NavigationArrow = ({
  direction,
  onClick,
  onMouseUp = undefined,
  onMouseDown = undefined,
  style = {},
}: NavigationArrowProps) => {
  const onClickHandler = () => {
    if (onClick) {
      onClick(direction);
    }
  };

  const onMouseDownHandler = () => {
    if (onMouseDown) {
      onMouseDown(direction);
    }
  };

  const onMouseUpHandler = () => {
    if (onMouseUp) {
      onMouseUp(direction);
    }
  };

  return (
    <IconButton
      icon={<FontAwesomeIcon icon={iconMap[direction]} size="1x"/>}
      onClick={onClickHandler}
      onMouseDown={onMouseDownHandler}
      onMouseUp={onMouseUpHandler}
      radius="rounded"
      size="large"
      style={{
        boxShadow: 'rgb(0 0 0 / 15%) 0 4px 8px 2px',
        border: 'none',
        width: '36px',
        height: ' 36px',
        position: 'absolute',
        zIndex: 9999,
        ...positionMap[direction],
        ...style,
      }}
    />
  );
};

export default NavigationArrow;
