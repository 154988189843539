import styled from 'styled-components';
import { isNumber } from 'lodash-es';

const ModernSelect__Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ModernSelect__Dropdown = styled.div<{ width?: string | number, minWidth?: string | number }>`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii.default};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow[1]};
  z-index: ${({ theme }) => theme.zIndex.modal};
  width: ${({ width }) => `${width ? isNumber(width) ? `${width}px` : width : '100%'}`};
  min-width: ${({ minWidth }) => `${minWidth ? isNumber(minWidth) ? `${minWidth}px` : minWidth : 'unset'}`};
  overflow: hidden;
`;

const ModernSelect__Dropdown__Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 400px;
`;

const ModernSelect__Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
`;

const ModernSelect__Empty__Message = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
`;

const ModernSelect__Item = styled.div<{ isSelected: boolean}>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.radii.default};
  width: 100%;
  min-height: 60px;
  padding: ${({ theme }) => theme.space.small};
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.textColor : theme.colors.separator)};

  &:hover {
    border-color: ${({ theme }) => theme.colors.textColor};
  }
`;

const ModernSelect__Item__Radio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.space.small};
`;

const ModernSelect__Item__Value = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const ModernSelect__Options = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.small};

  ${ModernSelect__Item}:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.small};
  }
`;

const ModernSelect__Dropdown__Controls = styled.div<{ maxHeight?: number | string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.space.medium};
  border-top: 1px solid ${({ theme }) => theme.colors.separator};
`;

export const Styled = {
  ModernSelect__Loader,
  ModernSelect__Empty__Message,
  ModernSelect__Container,
  ModernSelect__Dropdown,
  ModernSelect__Dropdown__Content,
  ModernSelect__Dropdown__Controls,
  ModernSelect__Options,
  ModernSelect__Item,
  ModernSelect__Item__Radio,
  ModernSelect__Item__Value,
};
