import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import axios from 'axios';

type UseOmnibusPriceProps = {
  url: string;
  disabled?: boolean;
};

interface OmnibusPrice {
  amount: string;
  currency: string;
}

interface UseOmnibusPriceData {
  isLoading: boolean;
  isError: boolean;
  isReady: boolean;
  data: {
    Lowest: OmnibusPrice;
    Previous: OmnibusPrice;
  } | null;
}

const useOmnibusPrice = ({ url, disabled = false }: UseOmnibusPriceProps) => {
  const [omnibusPrice, setOmnibusPrice] = useState<UseOmnibusPriceData>({
    isLoading: false,
    isError: false,
    isReady: false,
    data: null,
  });

  useEffect(() => {
    if (url && !disabled && !omnibusPrice.isReady) {
      setOmnibusPrice((state) => ({
        ...state,
        isLoading: true,
      }));

      axios
        .get(url)
        .then((data) => {
          const omnibus = data?.data?.OmnibusPrice;

          if (!data?.data?.err && !isEmpty(omnibus)) {
            setOmnibusPrice((state) => ({
              ...state,
              data: omnibus,
              isLoading: false,
              isError: false,
              isReady: true,
            }));
          } else {
            setOmnibusPrice((state) => ({
              ...state,
              isLoading: false,
              isError: true,
              isReady: true,
            }));
          }
        })
        .catch(() => {
          setOmnibusPrice((state) => ({
            ...state,
            isLoading: false,
            isError: true,
          }));
        });
    }
  }, [url, disabled, omnibusPrice?.isReady]);

  return omnibusPrice;
};

export default useOmnibusPrice;
