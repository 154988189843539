import React, { forwardRef } from 'react';
import {
  ColorProps,
  FontSizeProps,
  SpaceProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  BoxShadowProps,
  BackgroundProps,
  BorderRadiusProps,
} from 'styled-system';

import { Styled } from './FlexBox.styles';

type StyledSystemProps = ColorProps &
  FontSizeProps &
  SpaceProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  BoxShadowProps &
  BackgroundProps &
  BorderRadiusProps;

type FlexBoxProps = {
  children?: React.ReactNode;
  borderRadius?: string | number;
  style?: React.CSSProperties;
  id?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onWheel?: (e: React.WheelEvent<HTMLDivElement>) => void;
  onDrop?: (e: React.DragEvent) => void;
  onDragEnter?: (e: React.DragEvent) => void;
  onDragLeave?: (e: React.DragEvent) => void;
  onDragOver?: (e: React.DragEvent) => void;
  as?: any;
} & StyledSystemProps;

const FlexBox = forwardRef<HTMLDivElement, FlexBoxProps>(({
  id = undefined,
  as = 'div',
  children = null,
  onClick = undefined,
  onDrop = undefined,
  onDragLeave = undefined,
  onDragOver = undefined,
  onDragEnter = undefined,
  onMouseLeave = undefined,
  onMouseEnter = undefined,
  onMouseOver = undefined,
  onWheel = undefined,
  borderRadius = 0,
  className = '',
  style = {},
  ...props
}, ref) => (
  <Styled.FlexBox
    as={as}
    ref={ref}
    className={className}
    onClick={onClick}
    onDrop={onDrop}
    onDragEnter={onDragEnter}
    onDragOver={onDragOver}
    onMouseLeave={onMouseLeave}
    onMouseEnter={onMouseEnter}
    onMouseOver={onMouseOver}
    onWheel={onWheel}
    borderRadius={borderRadius}
    style={style}
    {...{ ...(id ? { id } : {}) }}
    {...props}
  >
    {children}
  </Styled.FlexBox>
),
);

export default FlexBox;
