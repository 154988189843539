import { css } from 'styled-components';

const Toastify = css`
    .Toastify {
      &__close-button {
        right: 10px;
        top: 10px;
      }

      &__toast {
        border-left: 4px solid transparent;

        &-icon {
          height: 30px;
          align-self: flex-start;
        }

        &-container {
          min-width: 340px;
          max-width: 340px;

          ${({ theme }) => `${theme.mediaQueries.xs} {
             min-width: unset;
             max-width: unset;
          }`}
        }

        &--error {
          border-color: var(--toastify-icon-color-error);
        }

        &--info {
          border-color: var(--toastify-icon-color-info);
        }

        &--warning {
          border-color: var(--toastify-icon-color-warning);
        }

        &--success {
          border-color: var(--toastify-icon-color-success);
        }
      }
    }
`;

export default Toastify;
