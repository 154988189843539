import React from 'react';
import { useTranslation } from 'react-i18next';
import { has } from 'lodash-es';
import { useDispatch } from 'react-redux';

import { showToast, TOAST_INFO } from '@ess/utils';

import { useSelector } from '@ess/store/core';
import { showMap, showSettingsDrawer } from '@ess/store/slices/baseSlice';
import { setView } from '@ess/store/slices/searchResultsSlice';
import { expandFilters } from '@ess/store/slices/filtersFormSlice';

import Drawer from '@ess/ui/Drawer';

import useBreakpoint from '@ess/hooks/useBreakpoint';

import { ScrollBar } from '@ess/components/ScrollBar';

import { Sections } from '../Sections';

const REDUX_ACTION_MAP: any = {
  viewType: setView,
  mapShowed: showMap,
  filtersExpanded: expandFilters,
};

const AgentSettingsDrawer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showAgentSettingsDrawer = useSelector((state) => state.base.showSettingsDrawer);
  const breakpoint = useBreakpoint();

  const onCloseHandler = () => {
    dispatch(showSettingsDrawer(false));
  };

  const onChangeHandler = ({ name, value }: { name: string, value: any }) => {
    if (value !== null && has(REDUX_ACTION_MAP, name)) {
      dispatch(REDUX_ACTION_MAP[name](value));
    }

    showToast(TOAST_INFO, t('local_settings_saved'));
  };

  return (
    <Drawer
      top="var(--header-height)"
      title={t('lbl_agent_settings')}
      isOpen={showAgentSettingsDrawer}
      onClose={onCloseHandler}
      position="right"
      zIndex={9999}
      containerStyles={{ maxWidth: '400px', width: '100%' }}
      showOverlay={false}
    >
      <ScrollBar>
        <Sections onChange={onChangeHandler}/>
      </ScrollBar>
    </Drawer>
  );
};

export {
  AgentSettingsDrawer,
};
