import React, { CSSProperties, forwardRef } from 'react';
import { isString } from 'lodash-es';
import {
  SpaceProps, ColorProps, LayoutProps, FlexboxProps, BorderProps,
} from 'styled-system';

import { SizesEnum } from '@ess/types';

import Icon from '@ess/ui/Icon';
import Tooltip from '@ess/ui/Tooltip';
import Loader from '@ess/ui/Loader';

import { Styled } from './Button.styles';

type VariantsEnum = 'primary' | 'secondary' | 'navy' | 'gray' | 'blue' | 'darkGray' | 'transparent' | 'light';

type IconButtonProps = {
  label?: string
  size?: SizesEnum
  icon: string | React.ReactNode
  radius?: string
  iconSize?: string
  variant?: VariantsEnum
  width?: string
  disabled?: boolean
  isLoading?: boolean
  style?: CSSProperties
  as?: any
  onClick?: (event: React.MouseEvent) => void
  onMouseDown?: (event: React.MouseEvent) => void
  onMouseUp?: (event: React.MouseEvent) => void
} & SpaceProps & ColorProps & LayoutProps & FlexboxProps & BorderProps;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({
  icon,
  label = '',
  as = 'button',
  onClick = undefined,
  onMouseDown = undefined,
  onMouseUp = undefined,
  iconSize = 'inherit',
  width = 'auto',
  radius = 'square',
  disabled = false,
  isLoading = false,
  size = 'large' as SizesEnum,
  variant = 'transparent' as VariantsEnum,
  style = {},
  ...props
}, ref) => {
  const onClickHandler = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
  };

  const IconButtonComponent = (
    <Styled.IconButton
      as={as}
      ref={ref}
      type="button"
      onClick={onClickHandler}
      onPointerDown={onMouseDown}
      onPointerUp={onMouseUp}
      disabled={disabled || isLoading}
      aria-label={label}
      width={width}
      radius={radius}
      variant={variant}
      size={size}
      style={style}
      {...props}
    >
      {isLoading ? (
        <Loader/>
      ) : (
        <>
          {isString(icon) ? (
            <Icon size={iconSize} iconName={icon} />
          ) : (
            icon
          )}
        </>
      )}
    </Styled.IconButton>
  );

  return (
    <>
      {label ? (
        <Tooltip content={label} theme="bubble-wide">
          {IconButtonComponent}
        </Tooltip>
      ) : (
        IconButtonComponent
      )}
    </>
  );
});

export default IconButton;
