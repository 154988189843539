import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { CONTENT_SERVICE_URL } from '@ess/constants/api';

import { promiseRequest } from '@ess/v5-data-provider/request';

import { getCurrentLanguage } from '@ess/utils';
import { useAppConfig } from '@ess/context/AppConfigContext';

type State = 'idle' | 'loading' | 'error' | 'ready';

type UrlType = 'details' | 'booking' | 'system';

type TravelCompassState = {
  state: State;
  url: string | null;
};

const initialState = {
  state: 'idle' as State,
  url: null,
};

const idMap: Record<string, string> = {
  details: 'OfferId',
  booking: 'BookingId',
};

const getAllowedOperators = (config: Record<string, any>): string[] => {
  const allowedOperators: string[] = [];

  Object.keys(config).map((operator) => {
    const properties = config[operator];
    if (properties?.travelCompass) {
      allowedOperators.push(operator);
    }
  });

  return allowedOperators;
};

const useTravelCompass = (operator?: string) => {
  const { t } = useTranslation();
  const { configOper } = useAppConfig();
  const [state, setState] = useState<TravelCompassState>(initialState);

  const allowedOperators = useMemo(
    () => getAllowedOperators(configOper),
    [configOper],
  );

  const isEnabled = operator && allowedOperators.includes(operator);

  const fetchUrl = async (type: UrlType, id?: string | number) => {
    const isSystemRequest = !['details', 'booking'].includes(type);

    let results = null;

    try {
      setState((state) => ({
        ...state,
        state: 'loading',
      }));

      toast.info(t('lbl_opening_travelcompass'), {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      const response = await promiseRequest(
        `${CONTENT_SERVICE_URL}TravelCompass`,
        {
          Language: getCurrentLanguage(),
          Currency: 'PLN',
          TravelCompass: {
            Type: type,
            ...(!isSystemRequest
              ? {
                [idMap[type]]: id,
              }
              : {}),
          },
        },
        3,
      );

      const url = response?.Sections?.TravelCompass?.Url;

      if (url) {
        results = {
          status: 'ready',
          url,
        };

        setState((state) => ({
          ...state,
          state: 'ready',
          url,
        }));
      } else {
        throw new Error('TRAVEL_COMPASS_ERROR');
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        state: 'error',
      }));

      results = {
        status: 'error',
        url: null,
      };

      toast.error(t('lbl_travelcompass_error'), {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    return results;
  };

  const openTravelCompass = async (type: UrlType, id?: string | number) => {
    try {
      const response = await fetchUrl(type, id);

      if (response?.url) {
        window.open(response.url, '_blank');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    ...state,
    openTravelCompass,
    isEnabled,
  };
};

export { useTravelCompass };
