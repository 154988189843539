import React, {
  CSSProperties, forwardRef, useEffect, memo,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import useWindowSizeSelector from '@ess/hooks/useWindowSizeSelector';

import { Button } from '@ess/ui/Button';

import ModalOverlay from '../../ModalOverlay';

import { Styled } from '../Modal.styles';

export type ConfirmModalProps = {
  title?: string
  id?: string
  children: React.ReactNode
  onClose?: () => void
  onOpen?: () => void
  onClick?: () => void
  onConfirm: () => void
  onDiscard: () => void
  isOpen?: boolean
  hasTarget?: boolean
  showOverlay?: boolean
  maxHeight?: number | string
  showCloseIcon?: boolean
  style?: CSSProperties
  appendTo?: Element | null
  width?: number | string
  theme?: string,
};

const ConfirmModal = forwardRef<HTMLDivElement | React.SetStateAction<HTMLDivElement | null>, ConfirmModalProps>(({
  children,
  onConfirm,
  onDiscard,
  showOverlay = true,
  id = undefined,
  title = undefined,
  onClick = undefined,
  onClose = undefined,
  onOpen = undefined,
  showCloseIcon = true,
  isOpen = false,
  appendTo = undefined,
  maxHeight = 0,
  theme = 'white',
  width = 400,
  style = {},
  ...props
}, ref) => {
  const { t } = useTranslation();
  const height = useWindowSizeSelector(({ height }) => height, [], isOpen);

  /**
   * Confirm button click handler.
   * @param event
   */
  const onConfirmHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    onConfirm();
  };

  /**
   *
   * Discard button click handler.
   * @param event
   */
  const onDiscardHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDiscard();
  };

  /**
   * Modal body click handler.
   * @param event
   */
  const onClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (onClick) {
      onClick();
    }
  };

  /**
   * Modal close button click handler.
   * @param event
   */
  const closeBtnClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  return (
    <>
      {createPortal(
        <>
          {isOpen && (
            <>
              { /* @ts-ignore */}
              <Styled.Modal
                ref={ref}
                key="modal"
                maxHeight={height}
                onClick={onClickHandler}
                themeType={theme}
                style={style}
                width={width}
                {...{ ...id ? { id } : {} }}
                {...props}
              >
                {title && (
                  <Styled.Modal__Header themeType={theme}>
                    {title && (
                      <Styled.Modal__Title>{title}</Styled.Modal__Title>
                    )}
                    {showCloseIcon && (
                      <Styled.Modal__CloseIcon onClick={closeBtnClickHandler}>
                        <FontAwesomeIcon icon={faTimes} color="inherit" size="1x"/>
                      </Styled.Modal__CloseIcon>
                    )}
                  </Styled.Modal__Header>
                )}
                <Styled.Modal__Content>
                  {children}
                </Styled.Modal__Content>
                <Styled.Modal__Controls isConfirmModal>
                  <Button
                    mr="small"
                    label={t('textNo')}
                    size="small"
                    width="60px"
                    variant="secondary"
                    onClick={onDiscardHandler}
                  />
                  <Button
                    label={t('textYes')}
                    size="small"
                    width="60px"
                    onClick={onConfirmHandler}
                  />
                </Styled.Modal__Controls>
              </Styled.Modal>
              {showOverlay && (<ModalOverlay/>)}
            </>
          )}
        </>,
        (!appendTo ? document.querySelector('.modal-root') : appendTo) as HTMLElement,
      )}
    </>
  );
});

export default memo(ConfirmModal);
