import React, { CSSProperties, forwardRef } from 'react';
import {
  SpaceProps, LayoutProps, ColorProps, BorderProps, PositionProps,
} from 'styled-system';

import { Styled } from './Box.styles';

type StyledSystemProps = SpaceProps & LayoutProps & ColorProps & BorderProps & PositionProps;

type BoxProps = {
  children?: React.ReactNode;
  style?: CSSProperties;
  id?: string;
  dangerouslySetInnerHTML?: {__html: string} | undefined;
  as?: any;
  clearStyles?: boolean;
  onWheel?:(e: React.WheelEvent<HTMLDivElement>) => void;
} & StyledSystemProps;

const Box = forwardRef<HTMLDivElement, BoxProps>(({
  children = null,
  id = '',
  style = {},
  clearStyles = false,
  dangerouslySetInnerHTML = undefined,
  onWheel = undefined,
  ...props
}, ref) => (
  <Styled.Box
    ref={ref}
    className={clearStyles ? 'clear-styles' : null}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    style={style}
    onWheel={onWheel}
    {...{ ...id ? { id } : {} }}
    {...props}
  >
    {children}
  </Styled.Box>
));

export default Box;
