type Arguments = {
  price: {
    amount: string
    currency: string
  },
  config: {
    toFixed?: boolean
    round?: boolean
  }
}

const defaultConfig = {
  toFixed: false,
  round: false,
};

export const getFormattedPrice = ({ price, config = defaultConfig }: Arguments) => {
  const priceConfig = { ...defaultConfig, ...config };
  const formatted = { ...price };

  if (!formatted?.amount) {
    return {
      amount: '',
      currency: '',
    };
  }

  if (priceConfig.round) {
    formatted.amount = Math.ceil(Number(formatted.amount)).toString();
  }

  if (priceConfig.toFixed) {
    formatted.amount = Number(formatted.amount).toFixed(2).toString();
  }

  return formatted;
};
