import React, { forwardRef } from 'react';
import { SpaceProps, TypographyProps } from 'styled-system';

import { Styled } from './Icon.styles';

type IconProps = {
  iconName: string | object,
  onClick?: () => void,
  size?: string,
  color?: string,
  as?: any,
} & SpaceProps & TypographyProps;

const Icon = forwardRef<HTMLElement, IconProps>(({
  as = 'div',
  onClick = undefined,
  size = '16px',
  color = 'inherit',
  iconName,
  ...props
}, ref) => (
  <Styled.Icon
    as={as}
    size={size}
    color={color}
    ref={ref}
    className={`fa fa-${iconName}`}
    {...props}
  />
));

export default Icon;
